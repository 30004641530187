import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PortfolioIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={'Portfolio'}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={'Portfolio'}>
      <Seo title="Portfolio" />
      <div style={{ marginTop: '-24px', marginBottom: '24px' }}>
        Some example work. For more illustrations & comics, check out my <a href="https://instagram.com/ohpatpatt">Instagram</a> and <a href="https://twitter.com/ohpatpatt">Twitter</a>!
      </div>
      <div className="portfolio-container">
        {posts.map(post => {
          if (!post.frontmatter.portfolio) {
            return;
          }

          return (
            <div className="portfolio-thumb" key={post.fields.slug}>
              <Link to={post.fields.slug} itemProp="url">
                <img src={post.frontmatter.featuredImage}></img>
              </Link>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [fields___slug], order: ASC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          portfolio
          featuredImage
        }
      }
    }
  }
`
